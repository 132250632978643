<template>
  <div class="coordination-container">
	<el-row>
		<!-- 左侧 -->
		<el-col :span="18">
			<el-row :gutter="20">
				<el-col :span="8">
					<div class="bg-purple">
						<h1>{{ myPendingTotal }}<span>个</span></h1>
						<p>待处理</p>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="bg-purple" style="background:#67C23A">
						<h1>{{ myJoinTotal }}<span>个</span></h1>
						<p>我参与</p>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="bg-purple" style="background:#E6A23C">
						<h1>{{ myCreateTotal }}<span>个</span></h1>
						<p>我发起</p>
					</div>
				</el-col>
			</el-row>
			<br>
			<el-row :gutter="20">
				<el-col :span="8" >
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>待处理</span>
							<el-button style="float: right; padding: 3px 0" type="text" @click="jumpTo('/teamWork/pending')">更多</el-button>
						</div>
						<div v-for="(item,index) in myPendingList" :key="item.id" class="text item" @click="toDetail(item,'myPending','detail')">
							<span class="title">{{ (index+1) + `、${item.name}`}}</span>
							<span v-if='item.state == 0' style="color:#E6A23C">进行中</span>
                    		<span v-if='item.state == 3' style="color:#909399">已结束</span>
						</div>
					</el-card>
				</el-col>
				<el-col :span="8">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>我参与</span>
							<el-button style="float: right; padding: 3px 0" type="text"  @click="jumpTo('/teamWork/join')">更多</el-button>
						</div>
						<div v-for="(item,index) in myJoinList" :key="item.id" class="text item" @click="toDetail(item,'myJoin','detail')">
							<span class="title">{{ (index+1) + `、${item.name}`}}</span>
							<span v-if='item.state == 0' style="color:#E6A23C">进行中</span>
                    		<span v-if='item.state == 3' style="color:#909399">已结束</span>
						</div>
					</el-card>
				</el-col>
				<el-col :span="8">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>我发起</span>
							<el-button style="float: right; padding: 3px 0" type="text"  @click="jumpTo('/teamWork/create')">更多</el-button>
						</div>
						<div v-for="(item,index) in myCreateList" :key="item.id" class="text item" @click="toDetail(item,'myCreate','detail')">
							<span class="title">{{ (index+1) + `、${item.name}`}}</span>
							<span v-if='item.state == 0' style="color:#E6A23C">进行中</span>
                    		<span v-if='item.state == 3' style="color:#909399">已结束</span>
						</div>
					</el-card>
				</el-col>
			</el-row>
			
		</el-col>

		<!-- 右侧 -->
		<el-col :span="5">
			<!-- 创建协同 -->
			<div class="team-btn">
				<el-button type='primary' round @click="createTeamWork">发起协同</el-button>
			</div>
			

			<!-- 动态 -->
			<div class="coordination-trends">
				<h1 class="coordination-title">动态</h1>
				<el-timeline v-if='myActiveList.length != 0'>
					<el-timeline-item :timestamp="item.createDate | date('yyyy-MM-dd')" placement="top" v-for="item in myActiveList" :key="item.id" >
						<el-card>
							<div @click="toDetail(item,'myPending','msg')" style="cursor:pointer">
								<h4>{{item.content}}</h4>
								<p>{{item.senderName}} 提交于 {{item.createDate | date}}</p>
							</div>
						</el-card>
					</el-timeline-item>
				</el-timeline>
				<div v-else class="tips">暂无动态</div>
			</div>
		</el-col>
	</el-row>

	<!-- 协同详情 -->
	<TeamWorkDetail ref="TeamWorkDetail"></TeamWorkDetail>

	<!-- 发起协同 -->
	<CreateTeamWork ref='teamWork' @clearSearch="loadByQuery('myCreate')"></CreateTeamWork>

  </div>
</template>

<script>
import CreateTeamWork from './components/CreateTeamWork.vue';
import TeamWorkDetail from './components/Detail.vue'
export default {
	components:{
		TeamWorkDetail,
		CreateTeamWork
	},
	computed: { 
		// 待处理列表
		myPendingList(){
            return this.$store.state.team.views.myPending.list || []
        },
		// 待处理总数
        myPendingTotal(){
            return this.$store.state.team.views.myPending.paging.total;
        },
		// 我创建列表 
		myCreateList(){
            return this.$store.state.team.views.myCreate.list || []
        },
		// 我创建总数
        myCreateTotal(){
            return this.$store.state.team.views.myCreate.paging.total;
        },
		// 我加入列表
		myJoinList(){
            return this.$store.state.team.views.myJoin.list || []
        },
		// 我加入总数
        myJoinTotal(){
            return this.$store.state.team.views.myJoin.paging.total;
        },
		// 我的动态
		myActiveList(){
            return this.$store.state.team.views.myActive.list || []
        },
	},
	methods: {
       // 加载列表
        loadByQuery(viewerKey){
            let interfaceName = 'getMyPendingList'
            interfaceName = viewerKey == 'myPending'? 'getMyPendingList':(viewerKey == 'myCreate' ? "getMyCreateList":"getMyJoinList") 
            this.$store.dispatch('team/loadByQuery',{
                viewerKey:viewerKey,
                reset:true,
                query:{
					pageNum:1,
					pageSize:5
				},
                interfaceName:interfaceName
            })
        }, 
		// 跳转
		jumpTo(path){
			this.$router.push({ path:path })
		},
		// 打开详情
		toDetail(row,viewerKey,type){
			if(type == 'msg'){
				// 如果是消息
				this.$refs['TeamWorkDetail'].show( { id:row.themeId },viewerKey)
			}else{
				this.$refs['TeamWorkDetail'].show(row,viewerKey)
			}
		},
		// 获取动态
		getMessageByType(){
			this.$store.dispatch('team/loadByQuery',{
                viewerKey:'myActive',
                reset:true,
                query:{
					pageNum:1,
					pageSize:1000,
					messageType:5
				},
                interfaceName:'getMessageByType'
            })
		},
		// 创建协同
		createTeamWork(){
			this.$refs.teamWork.init();
		}
	},
	created() {},
	mounted() {
		this.loadByQuery('myPending');//待处理
		this.loadByQuery('myCreate');//我创建
		this.loadByQuery('myJoin');//我参与
		this.getMessageByType()//我的动态
	},
};
</script>

<style lang='less' scoped>
@import "../../styles/index";
.coordination-container {
	.team-btn{
		padding-top: 30px;
		.el-button{
			width: 100%;
		}
	}
	.border-style{
		border: 1px solid #DCDFE6;
		box-sizing: border-box;
		padding: 10px 20px;
		margin-bottom: 20px;
	}
	.el-card{
		.text{
			font-size: 14px;
			line-height: 35px;
			border-bottom: 1px dashed #efefef;
			cursor: pointer;
		
			display: flex;
			justify-content: space-between;
			.title{
				width: 80%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
	.bg-purple {
		background: #409EFF;
		border-radius: 5px;
		padding: 10px;
		text-align: center;
		margin-top: 30px;
		color: #fff;
		h1{
			margin: 10px;
			font-size: 30px;
			span{
				font-size: 16px;
				margin-left: 5px;
			}
		}
		p{
			font-size: 18px;
		}
	}
	.coordination-title{
		margin: 0 0 10px 0;
		font-weight: bold;
		color: #2c3e50;
		font-size: 18px;
	}
	/deep/.el-col-5{
		float: right;
		width: 23%;
	}
	.coordination-trends{
		margin-top: 20px;
		.el-timeline{
			height: calc(100vh - 180px) ;
			overflow: scroll;
		}
		.tips{
			font-size: 14px;
			color: #999;
			text-align: center;
			margin-top: 50px;
		}
	}
	.create-container /deep/.el-button{
		width: 100%;
	}
	/deep/.el-tabs__item{
		font-size: 18px;
		color: #999;
		font-weight: normal;
	}
	/deep/.el-tabs__item.is-active {
		color: #2c3e50;
		font-weight: bold;
	}
	/deep/.el-tabs__active-bar{
		background-color: red;
	}
	/deep/.el-timeline{
		padding-left: 0;
		.el-card__body{
			padding: 10px;
			h4{
				margin: 0 0 10px 0;
				word-break: break-all;
			}
			p{
				font-size: 12px;
				margin: 0;
				color: #666;
			}
		}
	}
}

</style>